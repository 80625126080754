import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Fullstack Developers VS Specific Developer",
  "author": "tzookb",
  "type": "post",
  "date": "2018-02-27T11:58:42.000Z",
  "url": "/2018/02/fullstack-developers-vs-specific-developer/",
  "categories": ["Uncategorized"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`What are FrontEnd, Backend & Fullstack developers {#whatarefrontendbackendfullstackdevelopers}`}</h4>
    <p>{`As developers we have huge amounts of skills to master even if we focus on a single language or a single type of development. Luckily there are a lot of intersections no matter what language you use. Like: design patterns, dependency injection, MVC, controllers, factories etc.`}</p>
    <h5>{`Frontend Developer {#frontenddeveloper}`}</h5>
    <p>{`As a FrontEnd developer you have numerous options of different libraries like: React, Vue.js, Angular, Amber and many more. With those “core” frameworks you will probably need to master, bundling with: webpack, rolloup, browserify, gulp, grunt etc. Let’s not forget that you will need to know html, css or even Sass, Less or other css extenders.`}</p>
    <p>{`The things you need to know in our days to build a proper app are increasing rapidly from the “good old days” of let’s do some jquery and include those files to the page.`}</p>
    <h5>{`Backend Developer {#backenddeveloper}`}</h5>
    <p>{`As a backend developer the amount of tools and languages you can use are quite high as well. You can use compiled languages like: java, C, .Net, Go etc, Or use scripting languages like: python, ruby, node (javascript), php etc.`}</p>
    <p>{`Now after you chosen you desired language you will probably want to use external packages and frameworks and each languages has its own tools: php `}{`–`}{` composer, laravel, symfony, Zend etc. Node `}{`–`}{` npm, express, Koa, SailJs etc.`}</p>
    <p>{`2 hurdles are behind us, now we need a place to store our data, we can use relational databases, NoSql databases or “simple” key value storages, it really depends on your design and data structure. Quick list preview: MySql, Mongo, ElasticSearch, Redis etc.`}</p>
    <h5>{`Fullstack Developer {#fullstackdeveloper}`}</h5>
    <p>{`After we described the amount of skills needed foreach developer type as frontend and backend, we understand that as a fullstack developer you will need to know all of the above to be able to write a full functioning app.`}</p>
    <p>{`It surly looks daunting to learn all of that, but if you want to be a “fullstack” developer you will need to learn that.`}</p>
    <h4>{`Pros & Cons {#proscons}`}</h4>
    <p>{`After reviewing the differences between the type of developers and the needed skill for each we now can set apart the pros and cons for each.`}</p>
    <p>{`As a specific “side” developer Backend/Frontend you could focus on your skills and to really swim in the area you are in. You could know all the small tricks and small rabbit holes you have in a specific language or framework you use.`}</p>
    <p>{`There is great demand fo super highly skilled devs in many companies and there aren’t many developers who are super proficient like you.`}</p>
    <p>{`On the other side, our technology world keeps pushing forward and technologies and frameworks change quite rapidly. So you may be super skilled in one, but you could find yourself way back after some time.`}</p>
    <p>{`Even though programming is programming, after you get used to one language or tool for a long time it’s hard to jump to another quickly so just keep that in mind.`}</p>
    <p>{`For a “FullStack” developer the pros are quite obvious, you know a wider range of technologies so you can apply to many positions along the way. You can build full functioning apps from A-Z by yourself without waiting for backend APIs or frontend. You are used to various technologies so learning a new is relatively easy for you.`}</p>
    <p>{`On the other hand, when you are a “FullStack” developer you may be spreading yourself to thin. You are good in many things but not great in any.`}</p>
    <p>{`There is an assumption I see from time to time on the web that “fullstack” devs are not good enough for specific jobs, and it may be the case with some developers or with specific jobs (ie: jump to C source code).`}</p>
    <p>{`From my experience most jobs are perfect for fullstacks but again it depends on the company and position.`}</p>
    <h4>{`Different Clients and Companies {#differentclientsandcompanies}`}</h4>
    <p>{`In our industry we have various “clients” or companies to work with. It could be bootstrapped startups with low budget or it can be huge corporations with money coming out of their ears. In this wide range of companies there are different cultures or structure for the dev teams so it really depends on the company itself.`}</p>
    <p>{`I knew companies who divided their teams to “frontend” and “backend” that they work separately and only “communicate” through APIs.`}</p>
    <p>{`There are teams who are mixed with Backend+Frontend+QA+PM`}</p>
    <p>{`That is quite the same but a bit different. And surely we have teams who has devs who do both backend and frontend and backend and there is no real separation, it just depends on the current project and requirements.`}</p>
    <h4>{`Your Goals {#yourgoals}`}</h4>
    <p>{`As for myself I love learning new technologies, I enjoy experimenting and to test the limits of whatever tool I use. That could be a good and a bad thing (shiny object syndrome).`}</p>
    <p>{`I know it could be great to be an expert in Node.js and to really understand each bit and bit inside the V8 engine, but with my goals of delivering great products, my clients don’t really care from the V8 engine, they just want a great product and user experience.`}</p>
    <p>{`If you want to master specific language or framework, and be a figure that people would reach out to on the topic, so focus on one and drill down the hack of it.`}</p>
    <p>{`There are many “celeb” devs who focus on one specific subject and they are AMAZING! but it really depends on your goals and what do you enjoy doing.`}</p>
    <h4>{`How to do each? {#howtodoeach}`}</h4>
    <p>{`So if you got this line, I salute you and thank you for your time.`}</p>
    <p>{`So after we decided on which path we are going, we just need to take the plunge for the the next step.`}</p>
    <p>{`If you decided to become a “fullstack” it’s about time to brush up on your self learning skills first and start learning new skills and start building stuff, creating things on my end is the best learning option.`}</p>
    <p>{`If you want to become a master in one, simple choose one you more enjoy of and start digging in to it. Be sure to really learn the ins and outs and small potholes you can have. This is what will make you different from the rest of the devs.`}</p>
    <hr></hr>
    <p><strong parentName="p">{`P.S.`}</strong>{` this post is a part of my learning assignment for better writing. So here is how I started dividing my post:`}</p>
    <ul>
      <li parentName="ul">{`what is fullstack `}
        <ul parentName="li">
          <li parentName="ul">{`front`}</li>
          <li parentName="ul">{`backend`}</li>
          <li parentName="ul">{`fullstack`}</li>
        </ul>
      </li>
      <li parentName="ul">{`pros and cons `}
        <ul parentName="li">
          <li parentName="ul">{`specific`}</li>
          <li parentName="ul">{`fullstack`}</li>
        </ul>
      </li>
      <li parentName="ul">{`different clients `}
        <ul parentName="li">
          <li parentName="ul">{`startups`}</li>
          <li parentName="ul">{`corporations`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Your goals `}
        <ul parentName="li">
          <li parentName="ul">{`skillset`}</li>
          <li parentName="ul">{`expertise`}</li>
        </ul>
      </li>
      <li parentName="ul">{`How to `}
        <ul parentName="li">
          <li parentName="ul">{`be super specific`}</li>
          <li parentName="ul">{`fullstack`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      